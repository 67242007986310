.skill__inner {
    padding: 16px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 800px){ 
        flex-direction: column;
    }

    .skill__title {
        position: sticky;
        top: 70px;
        left: 0;
        width: 48%;
        height: 5vw;
        font-size: 4vw;
        font-weight: 900;
        line-height: 1.0;
        font-family: var(--mainKor-font);
        text-transform: uppercase;
        color: var(--black100);
        border-bottom: 0.4vw solid var(--black100);

        @media (max-width: 800px){ 
            width: 100%;
            margin-bottom: 10vw;
            font-size: 30px;
            height: auto;
            top: 68px;
            background-color: var(--mainBg-color);
        }

        em {
            font-size: 1.25rem;
            font-weight: 400;
            line-height: 2;
        }
    }
    .skill__desc {
        width: 50%;

        @media (max-width: 800px){ 
            width: 100%;
        }

        span {
            font-size: 5vw;
            line-height: 1;
            font-weight: 900;
            line-height: 1.3;
            font-family: var(--mainNum-font);

            @media (max-width: 800px){ 
                font-size: 10vw;
            }
        }
        h3 {
            font-size: 1.5rem;
            text-decoration: none;
            margin-bottom: 1vw;
        }
        p {
            margin-bottom: 30vh;
            font-size: 1.1rem;
        }
    }
}
