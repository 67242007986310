@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://websfont.github.io/nanumSquareNeo/nanumSquareNeo.css");
@import url("https://websfont.github.io/gmarket/gmarket.css");

.mont {
    font-family: "Montserrat";
}
.nanum {
    font-family: "nanumSquareNeo";
}
.gmarket {
    font-family: "gmarket";
}
